<template>
    <div>
        <div class="admin-content">
            <div class="tit-wrap1">
                <h3>입사지원서 양식 미리보기</h3>
                <div>
                    <button type="button" class="btn-box purple" @click="eventHandler.previewCloseClick()">미리보기 닫기</button>
                </div>
            </div>
                <div id="app" class="sub-resume-write resume-preview">
                    <RECEnrollApplication v-show="state.isEnrollApplication" :formInfo="state.formShowItems" :isPreview="state.parameter.isPreview"></RECEnrollApplication>
                    <RECInputCareers v-show="state.isInputCareers" :formInfo="state.formShowItems" :isPreview="state.parameter.isPreview" :isNew="state.isNew"></RECInputCareers>
                    <RECEtc v-show="state.isEtc" :formInfo="state.formShowItems" :isPreview="state.parameter.isPreview"></RECEtc>
                </div>
            <RECInterview v-show="state.isEssay" :formInfo="state.formShowItems" :isPreview="state.parameter.isPreview"></RECInterview>

            <div class="sub-btn-wrap1 flex-wrap right">
                <div>
                    <a href="javascript:void(0)" title="다음단계" class="koreit-btn1" v-if="!state.isEssay"  @click="eventHandler.nextClick()">다음단계</a>
                </div>
                <div>
                    <a href="javascript:void(0)" title="이전단계" class="koreit-btn2 gray" v-if="!state.isEnrollApplication" @click="eventHandler.prevClick()">이전단계</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import router from '@/router';
import { form } from '@/modules/recruit/REC_RC0110D.js';
import RECEnrollApplication from '@/components/REC_EnrollApplication.vue';
import RECInputCareers from '@/components/REC_InputCareers.vue';
import RECEtc from '@/components/REC_Etc.vue';
import RECInterview from '@/components/REC_Interview.vue';
export default {
    components: { RECEnrollApplication, RECInputCareers, RECEtc, RECInterview },
    setup() {
        // 라우트
        const vrt = useRoute();

        // 스토어
        const store = useStore();

        // 이력서 등록
        const RECEnrollApplication = ref(null)

        // 경력서 등록
        const RECInputCareers = ref(null)

        // state
        var state = reactive({

            dataTable: {

                // 양식 정보
                formItems: new Array()
            }

            , parameter: {

                // 양식 번호
                formNo: !!vrt.query.FormNo ? vrt.query.FormNo : null

                // 미리보기 여부
                , isPreview: !!vrt.query.isPreview ? vrt.query.isPreview : 'N'
            }

            // 기본정보 등록 여부
            , isEnrollApplication: false

            // 이력서 등록 여부
            , isInputCareers: false

            // 어학/자격/기타 사항 등록 여부
            , isEtc: false

            // 자기소개서 등록 신입 여부
            , isEssay: false

            // 경력 신입/경력 여부
            , isNew: false

            // 현재 화면 정보
            , pageInfo: ''

            , formShowItems: {

                // 현재연봉
                isShowCurntSalAmt: false

                // 희망연봉
                , isShowHopeSalAmt: false

                // 성격상 장/단점
                , isShowPsnltyCont: false

                // 병역사항
                , isShowAmy: false

                // 학력사항
                , isShowSchool: false

                // 자격증/수상내역
                , isShowCertification: false

                // 외국어
                , isShowForeignLang: false

                // 첨부파일
                , isShowFile: false

                // 경력(전체)
                , isShowCarrer: false

                // 자기소개서(경력)
                , isShowCarrerEssay: false

                // 자기소개서(신입)
                , isShowNewEssay: false
            }
        });

        // 메소드
        const methods = {

            // 양식 상세 조회
            getFormDetail: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                // 상세화면 formNo로 데이터테이블 세팅
                form.getFormDetail(state.parameter.formNo).then(result => {

                    state.dataTable.formItems = result.Table1.rows;

                }).finally(() => {

                    state.dataTable.formItems.forEach(row => {
                        row.ARTCL_USE_YN = row.ARTCL_USE_YN === 'Y' ? true : false;

                        switch(row.FORM_CONT_CD) {
                            // 현재연봉
                            case 'RC005105':
                                state.formShowItems.isShowCurntSalAmt = row.ARTCL_USE_YN
                                break
                            // 희망연봉
                            case 'RC005110':
                                state.formShowItems.isShowHopeSalAmt = row.ARTCL_USE_YN
                                break
                            // 성격상 장/단점
                            case 'RC005115':
                                state.formShowItems.isShowPsnltyCont = row.ARTCL_USE_YN
                                break
                            // 병역사항
                            case 'RC005120':
                                state.formShowItems.isShowAmy = row.ARTCL_USE_YN
                                break
                            // 학력사항
                            case 'RC005125':
                                state.formShowItems.isShowSchool = row.ARTCL_USE_YN
                                break
                            // 자격증/수상내역
                            case 'RC005130':
                                state.formShowItems.isShowCertification = row.ARTCL_USE_YN
                                break
                            // 외국어
                            case 'RC005135':
                                state.formShowItems.isShowForeignLang = row.ARTCL_USE_YN
                                break
                            // 첨부파일
                            case 'RC005140':
                                state.formShowItems.isShowFile = row.ARTCL_USE_YN
                                break
                            // 경력(전체)
                            case 'RC005205':
                                state.formShowItems.isShowCarrer = row.ARTCL_USE_YN
                                break
                            // 자기소개서(경력)
                            case 'RC005305':
                                state.formShowItems.isShowCarrerEssay = row.ARTCL_USE_YN
                                break
                            // 자기소개서(신입)
                            case 'RC005310':
                                state.formShowItems.isShowNewEssay = row.ARTCL_USE_YN
                                break
                        }
                    })

                    state.dataTable.formItems.forEach(row => {
                        row.FORM_CONT_CD
                    })

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            }
        };

        // 이벤트 핸들러
        const eventHandler = {

            // 미리보기 닫기 클릭 이벤트
            previewCloseClick: () => {

                router.push({ name: 'REC_RC0100R' });
            } 

            // 다음단계 클릭 이벤트
            , nextClick: () => {
                
                let page = state.pageInfo

                switch(page) {
                    case 'inputCareers':
                        if(state.formShowItems.isShowCertification || state.formShowItems.isShowForeignLang || state.formShowItems.isShowFile){
                            state.pageInfo = 'etc'
                            state.isEnrollApplication = false
                            state.isInputCareers = false
                            state.isEtc = true
                            state.isEssay = false
                        }
                        else{
                            state.pageInfo = 'essay'
                            state.isEnrollApplication = false
                            state.isInputCareers = false
                            state.isEtc = false
                            state.isEssay = true
                        }
                        break

                    case 'etc':
                        state.pageInfo = 'essay'
                        state.isEnrollApplication = false
                        state.isInputCareers = false
                        state.isEtc = false
                        state.isEssay = true
                        break

                    default:
                        if(state.formShowItems.isShowCarrer){
                            if(state.formShowItems.isShowCarrerEssay){
                                state.pageInfo = 'inputCareers'
                                state.isEnrollApplication = false
                                state.isInputCareers = true
                                state.isEtc = false
                                state.isEssay = false
                                state.isNew = false
                            }else{
                                state.pageInfo = 'inputCareers'
                                state.isEnrollApplication = false
                                state.isInputCareers = true
                                state.isEtc = false
                                state.isEssay = false
                                state.isNew = true
                            }
                        }
                        else if(state.formShowItems.isShowCertification || state.formShowItems.isShowForeignLang || state.formShowItems.isShowFile){
                            state.pageInfo = 'etc'
                            state.isEnrollApplication = false
                            state.isInputCareers = false
                            state.isEtc = true
                            state.isEssay = false
                        }
                        else{
                            state.pageInfo = 'essay'
                            state.isEnrollApplication = false
                            state.isInputCareers = false
                            state.isEtc = false
                            state.isEssay = true
                        }
                        break
                }
            }

            // 이전단계 클릭 이벤트
            , prevClick: () => {
                
                let page = state.pageInfo

                switch(page) {
                    case 'inputCareers':
                        state.pageInfo = 'enrollApplication'
                        state.isEnrollApplication = true
                        state.isInputCareers = false
                        state.isEtc = false
                        state.isEssay = false
                        break

                    case 'etc':
                        if(state.formShowItems.isShowCarrer){
                            state.pageInfo = 'inputCareers'
                            state.isEnrollApplication = false
                            state.isInputCareers = true
                            state.isEtc = false
                            state.isEssay = false
                        }
                        else{
                            state.pageInfo = 'enrollApplication'
                            state.isEnrollApplication = true
                            state.isInputCareers = false
                            state.isEtc = false
                            state.isEssay = false
                        }
                        
                        break

                    default:
                        if(state.formShowItems.isShowCertification || state.formShowItems.isShowForeignLang || state.formShowItems.isShowFile){
                            state.pageInfo = 'etc'
                            state.isEnrollApplication = false
                            state.isInputCareers = false
                            state.isEtc = true
                            state.isEssay = false
                        }
                        else if(state.formShowItems.isShowCarrer){
                            if(state.formShowItems.isShowCarrerEssay){
                                state.pageInfo = 'inputCareers'
                                state.isEnrollApplication = false
                                state.isInputCareers = true
                                state.isEtc = false
                                state.isEssay = false
                                state.isNew = false
                            }else{
                                state.pageInfo = 'inputCareers'
                                state.isEnrollApplication = false
                                state.isInputCareers = true
                                state.isEtc = false
                                state.isEssay = false
                                state.isNew = true
                            }
                        }
                        else{
                            state.pageInfo = 'enrollApplication'
                            state.isEnrollApplication = true
                            state.isInputCareers = false
                            state.isEtc = false
                            state.isEssay = false
                        }
                        break
                }
            }
        };

        onMounted(() => {

            // 채용 입사지원서 양식 목록 초기조회
            methods.getFormDetail();

            state.isEnrollApplication = true
            state.pageInfo = 'enrollApplication'
        });

        return { state, methods, eventHandler, RECEnrollApplication, RECInputCareers, RECEtc, RECInterview };
    }
}
</script>